@import '~sanitize.css';
@import '~src/assets/scss/input-overrides';
@import '~src/assets/scss/image-locations';
@import '~src/assets/scss/sweetalert2';
@import '~src/assets/scss/overlay';
@import '~src/assets/scss/globals';
@import '~src/assets/scss/legend-colors';
@import '~src/assets/scss/widget-overrides';
@import '~src/assets/scss/badges';
@import '~@angular/cdk/overlay-prebuilt.css';

html {
  min-width: 300px;
  font-family: $font-family;
  font-size: $font-size;
  color: $color-text-dark;
  scroll-behavior: smooth;
  height: 100%;
}

body {
  min-width: 300px;
  background-color: $color-site-dark-background;
  height: 100%;
}

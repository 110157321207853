@import 'variables';
@import 'functions';

/*
  Pitch noise fixes
*/

.sr-bb {
  div[class*='__noise'], div[class*='_texture'] {
    background-repeat: repeat;
  }

  div[class*='stripes'] {
    background-size: 14.575% 100%;
    background-repeat: repeat;
  }

  /*
    Enable dark or light logo based on base color
  */
  text-align: left;
  background: $sr-widget-base;
}

.sr-bb.sr-rtl {
  text-align: right;
}

.sr-bb .sr-bb {
  background: none;
}

.sr-bb .srt-base-1 {
  background-color: transparent;
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-win {
  background-color: transparent;
  color: $sr-widget-home;
  border-color: $sr-widget-home;
}

.sr-bb .srt-base-1-draw {
  background-color: transparent;
  color: rgba($color-text-dark, 0.4);
  border-color: rgba($color-text-dark, 0.4);
}

.sr-bb .srt-base-1-lose {
  background-color: transparent;
  color: $sr-widget-away;
  border-color: $sr-widget-away;
}

.sr-bb .srt-base-1-is-active {
  background-color: rgba(37, 94, 189, 0.12);
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-is-active-2 {
  background-color: #e6e6e6;
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-is-hoverable:hover {
  background-color: rgba(37, 94, 189, 0.12);
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-primary {
  background-color: transparent;
  color: $sr-widget-primary;
  border-color: $sr-widget-primary;
}

.sr-bb .srt-base-1-home-1 {
  background-color: transparent;
  color: $sr-widget-home;
  border-color: $sr-widget-home;
}

.sr-bb .srt-base-1-away-1 {
  background-color: transparent;
  color: $sr-widget-away;
  border-color: $sr-widget-away;
}

.sr-bb .srt-base-1-home-2 {
  background-color: transparent;
  color: $sr-widget-home;
  border-color: #15376e;
}

.sr-bb .srt-base-1-away-2 {
  background-color: transparent;
  color: $sr-widget-away;
  border-color: #15376e;
}

.sr-bb .srt-base-1-home-3 {
  background-color: transparent;
  color: $sr-widget-home;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-away-3 {
  background-color: transparent;
  color: $sr-widget-away;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-home-4 {
  background-color: transparent;
  color: #5662a1;
  border-color: #5662a1;
}

.sr-bb .srt-base-1-away-4 {
  background-color: transparent;
  color: #ccaa64;
  border-color: #ccaa64;
}

.sr-bb .srt-base-1-home-5 {
  background-color: transparent;
  color: #09144e;
  border-color: #09144e;
}

.sr-bb .srt-base-1-away-5 {
  background-color: transparent;
  color: #765615;
  border-color: #765615;
}

.sr-bb .srt-base-1-background {
  background-color: white;
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-2 {
  background-color: white;
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-2-is-active {
  background-color: #e5ecf7;
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-2-is-hoverable:hover {
  background-color: #e5ecf7;
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-3 {
  background-color: #e5ecf7;
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-3-is-active {
  background-color: #dae4f4;
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-3-is-hoverable:hover {
  background-color: #dae4f4;
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-3-background {
  background-color: white;
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-4 {
  background-color: #f4f7fc;
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-5-is-active {
  background-color: #c6d5ee;
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-5-is-hoverable:hover {
  background-color: #c6d5ee;
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-6 {
  background-color: transparent;
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.3);
}

.sr-bb .srt-primary-1 {
  background-color: $sr-widget-primary;
  color: $sr-widget-base;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-1-is-active {
  background-color: #3b6ec4;
  color: $sr-widget-base;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-1-is-hoverable:hover {
  background-color: #3b6ec4;
  color: $sr-widget-base;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-1-is-disabled {
  background-color: #517eca;
  color: $sr-widget-base;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-2 {
  background-color: #1a4284;
  color: $sr-widget-base;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-3 {
  background-color: #3b6ec4;
  color: $sr-widget-base;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-4 {
  background-color: #7c9ed7;
  color: $sr-widget-base;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-5 {
  background-color: #7c9ed7;
  color: $sr-widget-base;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-5-is-hoverable:hover {
  background-color: $sr-widget-primary;
  color: $sr-widget-base;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-6 {
  background-color: #15376e;
  color: $sr-widget-base;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-7 {
  background-color: $sr-widget-primary;
  color: $sr-widget-base;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-8 {
  background-color: $sr-widget-primary;
  color: $sr-widget-base;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-8-is-active-1 {
  background-color: #3b6ec4;
  color: $sr-widget-base;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-8-is-active-2 {
  background-color: #3b6ec4;
  color: $sr-widget-base;
  border-color: $sr-widget-base;
}

.sr-bb .srt-primary-9 {
  background-color: #becfeb;
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-primary-10 {
  background-color: #517eca;
  color: $sr-widget-base;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-11 {
  background-color: #3b6ec4;
  color: $sr-widget-base;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-primary-12 {
  background-color: $sr-widget-primary;
  color: $sr-widget-base;
  border-color: $sr-widget-base;
}

.sr-bb .srt-primary-13 {
  background-color: #f4f7fc;
  color: $sr-widget-base;
  border-color: rgba(37, 94, 189, 0.3);
}

.sr-bb .srt-base-1-primary-1 {
  background-color: transparent;
  color: $sr-widget-primary;
  border-color: $sr-widget-primary;
}

.sr-bb .srt-base-1-primary-2 {
  background-color: transparent;
  color: #1a4284;
  border-color: #1a4284;
}

.sr-bb .srt-base-1-primary-3 {
  background-color: transparent;
  color: #3b6ec4;
  border-color: #3b6ec4;
}

.sr-bb .srt-base-1-primary-4 {
  background-color: transparent;
  color: #7c9ed7;
  border-color: #7c9ed7;
}

.sr-bb .srt-base-1-primary-5 {
  background-color: transparent;
  color: #7c9ed7;
  border-color: #7c9ed7;
}

.sr-bb .srt-base-1-primary-6 {
  background-color: transparent;
  color: #15376e;
  border-color: #15376e;
}

.sr-bb .srt-base-1-primary-7 {
  background-color: transparent;
  color: $sr-widget-primary;
  border-color: $sr-widget-primary;
}

.sr-bb .srt-base-1-primary-8 {
  background-color: transparent;
  color: $sr-widget-primary;
  border-color: $sr-widget-primary;
}

.sr-bb .srt-base-1-primary-9 {
  background-color: transparent;
  color: #becfeb;
  border-color: #becfeb;
}

.sr-bb .srt-base-1-primary-10 {
  background-color: transparent;
  color: #517eca;
  border-color: #517eca;
}

.sr-bb .srt-base-1-primary-11 {
  background-color: transparent;
  color: #3b6ec4;
  border-color: #3b6ec4;
}

.sr-bb .srt-base-1-primary-13 {
  background-color: $sr-widget-base;
  color: #f4f7fc;
  border-color: rgba(37, 94, 189, 0.3);
}

.sr-bb .srt-base-1-neutral-1 {
  background-color: transparent;
  color: #dedede;
  border-color: #dedede;
}

.sr-bb .srt-base-1-neutral-2 {
  background-color: transparent;
  color: #1a1a1a;
  border-color: #1a1a1a;
}

.sr-bb .srt-base-1-neutral-3 {
  background-color: transparent;
  color: rgba($color-text-dark, 0.12);
  border-color: rgba($color-text-dark, 0.12);
}

.sr-bb .srt-base-1-neutral-4 {
  background-color: transparent;
  color: #595959;
  border-color: #595959;
}

.sr-bb .srt-base-1-neutral-5 {
  background-color: transparent;
  color: #404040;
  border-color: #404040;
}

.sr-bb .srt-base-1-neutral-6 {
  background-color: transparent;
  color: #c2c2c2;
  border-color: #c2c2c2;
}

.sr-bb .srt-base-1-neutral-7 {
  background-color: transparent;
  color: #595959;
  border-color: #595959;
}

.sr-bb .srt-base-1-neutral-8 {
  background-color: transparent;
  color: #dedede;
  border-color: #dedede;
}

.sr-bb .srt-base-1-neutral-9 {
  background-color: transparent;
  color: #f0f0f0;
  border-color: #f0f0f0;
}

.sr-bb .srt-base-1-neutral-10 {
  background-color: transparent;
  color: #e0e0e0;
  border-color: #e0e0e0;
}

.sr-bb .srt-base-1-neutral-11 {
  background-color: transparent;
  color: #999;
  border-color: #999;
}

.sr-bb .srt-base-1-neutral-12 {
  background-color: transparent;
  color: #4d4d4d;
  border-color: #4d4d4d;
}

.sr-bb .srt-base-1-neutral-13 {
  background-color: transparent;
  color: #e6e6e6;
  border-color: #e6e6e6;
}

.sr-bb .srt-base-1-is-active-primary {
  background-color: rgba(37, 94, 189, 0.12);
  color: $sr-widget-primary;
  border-color: $sr-widget-primary;
}

.sr-bb .srt-base-1-is-active-home-1 {
  background-color: rgba(37, 94, 189, 0.12);
  color: $sr-widget-home;
  border-color: $sr-widget-home;
}

.sr-bb .srt-base-1-is-active-away-1 {
  background-color: rgba(37, 94, 189, 0.12);
  color: $sr-widget-away;
  border-color: $sr-widget-away;
}

.sr-bb .srt-base-1-is-active-home-2 {
  background-color: rgba(37, 94, 189, 0.12);
  color: $sr-widget-home;
  border-color: #15376e;
}

.sr-bb .srt-base-1-is-active-away-2 {
  background-color: rgba(37, 94, 189, 0.12);
  color: $sr-widget-away;
  border-color: #15376e;
}

.sr-bb .srt-base-1-is-active-home-3 {
  background-color: rgba(37, 94, 189, 0.12);
  color: $sr-widget-home;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-is-active-away-3 {
  background-color: rgba(37, 94, 189, 0.12);
  color: $sr-widget-away;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-is-active-home-4 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #5662a1;
  border-color: #5662a1;
}

.sr-bb .srt-base-1-is-active-away-4 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #ccaa64;
  border-color: #ccaa64;
}

.sr-bb .srt-base-1-is-active-home-5 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #09144e;
  border-color: #09144e;
}

.sr-bb .srt-base-1-is-active-away-5 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #765615;
  border-color: #765615;
}

.sr-bb .srt-base-1-is-active-primary-1 {
  background-color: rgba(37, 94, 189, 0.12);
  color: $sr-widget-primary;
  border-color: $sr-widget-primary;
}

.sr-bb .srt-base-1-is-active-primary-2 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #1a4284;
  border-color: #1a4284;
}

.sr-bb .srt-base-1-is-active-primary-3 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #3b6ec4;
  border-color: #3b6ec4;
}

.sr-bb .srt-base-1-is-active-primary-4 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #7c9ed7;
  border-color: #7c9ed7;
}

.sr-bb .srt-base-1-is-active-primary-5 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #7c9ed7;
  border-color: #7c9ed7;
}

.sr-bb .srt-base-1-is-active-primary-6 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #15376e;
  border-color: #15376e;
}

.sr-bb .srt-base-1-is-active-primary-7 {
  background-color: rgba(37, 94, 189, 0.12);
  color: $sr-widget-primary;
  border-color: $sr-widget-primary;
}

.sr-bb .srt-base-1-is-active-primary-8 {
  background-color: rgba(37, 94, 189, 0.12);
  color: $sr-widget-primary;
  border-color: $sr-widget-primary;
}

.sr-bb .srt-base-1-is-active-primary-9 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #becfeb;
  border-color: #becfeb;
}

.sr-bb .srt-base-1-is-active-primary-10 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #517eca;
  border-color: #517eca;
}

.sr-bb .srt-base-1-is-active-primary-11 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #3b6ec4;
  border-color: #3b6ec4;
}

.sr-bb .srt-base-1-is-active-neutral-1 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #dedede;
  border-color: #dedede;
}

.sr-bb .srt-base-1-is-active-neutral-2 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #1a1a1a;
  border-color: #1a1a1a;
}

.sr-bb .srt-base-1-is-active-neutral-3 {
  background-color: rgba(37, 94, 189, 0.12);
  color: rgba($color-text-dark, 0.12);
  border-color: rgba($color-text-dark, 0.12);
}

.sr-bb .srt-base-1-is-active-neutral-4 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #595959;
  border-color: #595959;
}

.sr-bb .srt-base-1-is-active-neutral-5 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #404040;
  border-color: #404040;
}

.sr-bb .srt-base-1-is-active-neutral-6 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #c2c2c2;
  border-color: #c2c2c2;
}

.sr-bb .srt-base-1-is-active-neutral-7 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #595959;
  border-color: #595959;
}

.sr-bb .srt-base-1-is-active-neutral-8 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #dedede;
  border-color: #dedede;
}

.sr-bb .srt-base-1-is-active-neutral-9 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #f0f0f0;
  border-color: #f0f0f0;
}

.sr-bb .srt-base-1-is-active-neutral-10 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #e0e0e0;
  border-color: #e0e0e0;
}

.sr-bb .srt-base-1-is-active-neutral-11 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #999;
  border-color: #999;
}

.sr-bb .srt-base-1-is-active-neutral-12 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #4d4d4d;
  border-color: #4d4d4d;
}

.sr-bb .srt-base-1-is-active-neutral-13 {
  background-color: rgba(37, 94, 189, 0.12);
  color: #e6e6e6;
  border-color: #e6e6e6;
}

.sr-bb .srt-home-1 {
  background-color: $sr-widget-home;
  color: $sr-widget-base;
  border-color: $sr-widget-home;
}

.sr-bb .srt-away-1 {
  background-color: $sr-widget-away;
  color: $sr-widget-text;
  border-color: $sr-widget-away;
}

.sr-bb .srt-home-2 {
  background-color: $sr-widget-home;
  color: $sr-widget-base;
  border-color: #15376e;
}

.sr-bb .srt-away-2 {
  background-color: $sr-widget-away;
  color: $sr-widget-text;
  border-color: #15376e;
}

.sr-bb .srt-home-3 {
  background-color: $sr-widget-home;
  color: $sr-widget-base;
  border-color: $sr-widget-base;
}

.sr-bb .srt-away-3 {
  background-color: $sr-widget-away;
  color: $sr-widget-text;
  border-color: $sr-widget-base;
}

.sr-bb .srt-home-4 {
  background-color: #5662a1;
  color: $sr-widget-base;
  border-color: #5662a1;
}

.sr-bb .srt-away-4 {
  background-color: #ccaa64;
  color: $sr-widget-text;
  border-color: #ccaa64;
}

.sr-bb .srt-home-5 {
  background-color: #09144e;
  color: $sr-widget-base;
  border-color: #09144e;
}

.sr-bb .srt-away-5 {
  background-color: #765615;
  color: $sr-widget-text;
  border-color: #765615;
}

.sr-bb .srt-home-6 {
  background-color: rgba(14, 30, 120, 0.2);
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-away-6 {
  background-color: rgba(182, 133, 33, 0.2);
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-home-6-is-hoverable:hover {
  background-color: rgba(14, 30, 120, 0.2);
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-away-6-is-hoverable:hover {
  background-color: rgba(182, 133, 33, 0.2);
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-1 {
  background-color: #dedede;
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-2 {
  background-color: #1a1a1a;
  color: $sr-widget-base;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-neutral-3 {
  background-color: rgba(0, 0, 0, 0.12);
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-4 {
  background-color: #595959;
  color: $sr-widget-base;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-neutral-5 {
  background-color: #404040;
  color: $sr-widget-base;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-neutral-6 {
  background-color: #c2c2c2;
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-7 {
  background-color: #595959;
  color: $sr-widget-base;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-neutral-8 {
  background-color: #dedede;
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-9 {
  background-color: #f0f0f0;
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-10 {
  background-color: #e0e0e0;
  color: rgba($color-text-dark, 0.82);
  border-color: rgba($color-text-dark, 0.12);
}

.sr-bb .srt-neutral-11 {
  background-color: #999;
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-12 {
  background-color: #4d4d4d;
  color: $sr-widget-base;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-neutral-13 {
  background-color: #e6e6e6;
  color: $sr-widget-text;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-win {
  background-color: $sr-widget-home;
  color: $sr-widget-base;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-draw {
  background-color: rgba(0, 0, 0, 0.4);
  color: $sr-widget-base;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-lose {
  background-color: $sr-widget-away;
  color: $sr-widget-text;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-text-secondary {
  opacity: 0.7;
  -webkit-font-smoothing: antialiased;
}

.sr-bb .srt-text-disabled {
  opacity: 0.35;
}

.sr-bb .srt-text-tertiary {
  opacity: 0.54;
}

.sr-bb .srt-primary-1 .srt-text-secondary, .sr-bb .srt-primary-1.srt-text-secondary,
.sr-bb .srt-primary-1-is-active .srt-text-secondary,
.sr-bb .srt-primary-1-is-active.srt-text-secondary,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-secondary,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-secondary,
.sr-bb .srt-primary-1-is-disabled .srt-text-secondary,
.sr-bb .srt-primary-1-is-disabled.srt-text-secondary,
.sr-bb .srt-primary-2 .srt-text-secondary,
.sr-bb .srt-primary-2.srt-text-secondary,
.sr-bb .srt-primary-3 .srt-text-secondary,
.sr-bb .srt-primary-3.srt-text-secondary,
.sr-bb .srt-primary-4 .srt-text-secondary,
.sr-bb .srt-primary-4.srt-text-secondary,
.sr-bb .srt-primary-5 .srt-text-secondary,
.sr-bb .srt-primary-5.srt-text-secondary,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-secondary,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-secondary,
.sr-bb .srt-primary-6 .srt-text-secondary,
.sr-bb .srt-primary-6.srt-text-secondary,
.sr-bb .srt-primary-7 .srt-text-secondary,
.sr-bb .srt-primary-7.srt-text-secondary,
.sr-bb .srt-primary-8 .srt-text-secondary,
.sr-bb .srt-primary-8.srt-text-secondary,
.sr-bb .srt-primary-8-is-active-1 .srt-text-secondary,
.sr-bb .srt-primary-8-is-active-1.srt-text-secondary,
.sr-bb .srt-primary-8-is-active-2 .srt-text-secondary,
.sr-bb .srt-primary-8-is-active-2.srt-text-secondary,
.sr-bb .srt-primary-9 .srt-text-secondary,
.sr-bb .srt-primary-9.srt-text-secondary,
.sr-bb .srt-primary-10 .srt-text-secondary,
.sr-bb .srt-primary-10.srt-text-secondary,
.sr-bb .srt-primary-11 .srt-text-secondary,
.sr-bb .srt-primary-11.srt-text-secondary,
.sr-bb .srt-primary-12 .srt-text-secondary,
.sr-bb .srt-primary-12.srt-text-secondary,
.sr-bb .srt-primary-13 .srt-text-secondary,
.sr-bb .srt-primary-13.srt-text-secondary {
  opacity: 0.8;
  -webkit-font-smoothing: antialiased;
}

.sr-bb .srt-primary-1 .srt-text-disabled, .sr-bb .srt-primary-1.srt-text-disabled,
.sr-bb .srt-primary-1-is-active .srt-text-disabled,
.sr-bb .srt-primary-1-is-active.srt-text-disabled,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-disabled,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-disabled,
.sr-bb .srt-primary-1-is-disabled .srt-text-disabled,
.sr-bb .srt-primary-1-is-disabled.srt-text-disabled,
.sr-bb .srt-primary-2 .srt-text-disabled,
.sr-bb .srt-primary-2.srt-text-disabled,
.sr-bb .srt-primary-3 .srt-text-disabled,
.sr-bb .srt-primary-3.srt-text-disabled,
.sr-bb .srt-primary-4 .srt-text-disabled,
.sr-bb .srt-primary-4.srt-text-disabled,
.sr-bb .srt-primary-5 .srt-text-disabled,
.sr-bb .srt-primary-5.srt-text-disabled,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-disabled,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-disabled,
.sr-bb .srt-primary-6 .srt-text-disabled,
.sr-bb .srt-primary-6.srt-text-disabled,
.sr-bb .srt-primary-7 .srt-text-disabled,
.sr-bb .srt-primary-7.srt-text-disabled,
.sr-bb .srt-primary-8 .srt-text-disabled,
.sr-bb .srt-primary-8.srt-text-disabled,
.sr-bb .srt-primary-8-is-active-1 .srt-text-disabled,
.sr-bb .srt-primary-8-is-active-1.srt-text-disabled,
.sr-bb .srt-primary-8-is-active-2 .srt-text-disabled,
.sr-bb .srt-primary-8-is-active-2.srt-text-disabled,
.sr-bb .srt-primary-9 .srt-text-disabled,
.sr-bb .srt-primary-9.srt-text-disabled,
.sr-bb .srt-primary-10 .srt-text-disabled,
.sr-bb .srt-primary-10.srt-text-disabled,
.sr-bb .srt-primary-11 .srt-text-disabled,
.sr-bb .srt-primary-11.srt-text-disabled,
.sr-bb .srt-primary-12 .srt-text-disabled,
.sr-bb .srt-primary-12.srt-text-disabled,
.sr-bb .srt-primary-13 .srt-text-disabled,
.sr-bb .srt-primary-13.srt-text-disabled {
  opacity: 0.6;
}

.sr-bb .srt-primary-1 .srt-text-tertiary, .sr-bb .srt-primary-1.srt-text-tertiary,
.sr-bb .srt-primary-1-is-active .srt-text-tertiary,
.sr-bb .srt-primary-1-is-active.srt-text-tertiary,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-tertiary,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-tertiary,
.sr-bb .srt-primary-1-is-disabled .srt-text-tertiary,
.sr-bb .srt-primary-1-is-disabled.srt-text-tertiary,
.sr-bb .srt-primary-2 .srt-text-tertiary,
.sr-bb .srt-primary-2.srt-text-tertiary,
.sr-bb .srt-primary-3 .srt-text-tertiary,
.sr-bb .srt-primary-3.srt-text-tertiary,
.sr-bb .srt-primary-4 .srt-text-tertiary,
.sr-bb .srt-primary-4.srt-text-tertiary,
.sr-bb .srt-primary-5 .srt-text-tertiary,
.sr-bb .srt-primary-5.srt-text-tertiary,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-tertiary,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-tertiary,
.sr-bb .srt-primary-6 .srt-text-tertiary,
.sr-bb .srt-primary-6.srt-text-tertiary,
.sr-bb .srt-primary-7 .srt-text-tertiary,
.sr-bb .srt-primary-7.srt-text-tertiary,
.sr-bb .srt-primary-8 .srt-text-tertiary,
.sr-bb .srt-primary-8.srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-1 .srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-1.srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-2 .srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-2.srt-text-tertiary,
.sr-bb .srt-primary-9 .srt-text-tertiary,
.sr-bb .srt-primary-9.srt-text-tertiary,
.sr-bb .srt-primary-10 .srt-text-tertiary,
.sr-bb .srt-primary-10.srt-text-tertiary,
.sr-bb .srt-primary-11 .srt-text-tertiary,
.sr-bb .srt-primary-11.srt-text-tertiary,
.sr-bb .srt-primary-12 .srt-text-tertiary,
.sr-bb .srt-primary-12.srt-text-tertiary,
.sr-bb .srt-primary-13 .srt-text-tertiary,
.sr-bb .srt-primary-13.srt-text-tertiary {
  opacity: 0.7;
}

.sr-bb .srt-icon {
  opacity: 0.33;
}

.sr-bb .srt-icon-secondary {
  opacity: 0.7;
}

.sr-bb .srt-elevation-1 {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.sr-bb .srt-elevation-2 {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.23), 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-elevation-3 {
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.26), 0 10 20 0 rgba(0, 0, 0, 0.19);
}

.sr-bb .srt-elevation-center-2 {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.23), 0 1px 5px rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-inset-top-1 {
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-1 {
  box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-top-2 {
  box-shadow: inset 0 3px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-2 {
  box-shadow: inset 0 -3px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-top-3 {
  box-shadow: inset 0 6px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-3 {
  box-shadow: inset 0 -6px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-fill-blue-card {
  fill: #2579ad;
}

.sr-bb .srt-stroke-blue-card {
  stroke: #2579ad;
}

.sr-bb .srt-fill-soccer-yellow-card {
  fill: #ffbf00;
}

.sr-bb .srt-stroke-soccer-yellow-card {
  stroke: #ffbf00;
}

.sr-bb .srt-fill-soccer-red-card {
  fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-red-card {
  stroke: #e43b3b;
}

.sr-bb .srt-stroke-soccer-substitution-in {
  stroke: #4fbe30;
  fill: transparent;
}

.sr-bb .srt-fill-soccer-substitution-in {
  fill: #4fbe30;
}

.sr-bb .srt-stroke-soccer-substitution-out {
  stroke: #e43b3b;
  fill: transparent;
}

.sr-bb .srt-fill-soccer-substitution-out {
  fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-own-goal {
  stroke: #e43b3b;
  fill: transparent;
}

.sr-bb .srt-fill-soccer-own-goal {
  fill: #e43b3b;
}

.sr-bb .srt-fill-soccer-relegation-1 {
  fill: #fdd835;
}

.sr-bb .srt-stroke-soccer-relegation-1 {
  stroke: #fdd835;
}

.sr-bb .srt-fill-soccer-relegation-2 {
  fill: #ffb848;
}

.sr-bb .srt-stroke-soccer-relegation-2 {
  stroke: #ffb848;
}

.sr-bb .srt-fill-soccer-relegation-3 {
  fill: #ef6c00;
}

.sr-bb .srt-stroke-soccer-relegation-3 {
  stroke: #ef6c00;
}

.sr-bb .srt-fill-soccer-relegation-4 {
  fill: #e93a34;
}

.sr-bb .srt-stroke-soccer-relegation-4 {
  stroke: #e93a34;
}

.sr-bb .srt-fill-soccer-relegation-5 {
  fill: #941d1d;
}

.sr-bb .srt-stroke-soccer-relegation-5 {
  stroke: #941d1d;
}

.sr-bb .srt-fill-soccer-promotion-1 {
  fill: #51d151;
}

.sr-bb .srt-stroke-soccer-promotion-1 {
  stroke: #51d151;
}

.sr-bb .srt-fill-soccer-promotion-2 {
  fill: #1b911b;
}

.sr-bb .srt-stroke-soccer-promotion-2 {
  stroke: #1b911b;
}

.sr-bb .srt-fill-soccer-promotion-3 {
  fill: #0e8094;
}

.sr-bb .srt-stroke-soccer-promotion-3 {
  stroke: #0e8094;
}

.sr-bb .srt-fill-soccer-promotion-4 {
  fill: #0a6cce;
}

.sr-bb .srt-stroke-soccer-promotion-4 {
  stroke: #0a6cce;
}

.sr-bb .srt-fill-soccer-promotion-5 {
  fill: #4a9fe4;
}

.sr-bb .srt-stroke-soccer-promotion-5 {
  stroke: #4a9fe4;
}

.sr-bb .srt-nfl-timeout-1 {
  background-color: #f5a623;
  color: $sr-widget-base;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-nfl-timeout-2 {
  background-color: rgba(245, 166, 35, 0.3);
  color: $sr-widget-base;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-nfl-penalty {
  background-color: #f4a621;
  color: $sr-widget-base;
  border-color: #f4a621;
}

.sr-bb .srt-nfl-negative-yards-1 {
  background-color: #ac182e;
  color: $sr-widget-base;
  border-color: #ac182e;
}

.sr-bb .srt-nfl-negative-yards-2 {
  background-color: #ac182e;
  color: $sr-widget-base;
  border-color: #ac182e;
}

.sr-bb .srt-nfl-first-line {
  background-color: #4b90de;
  color: $sr-widget-base;
  border-color: #4b90de;
}

.sr-bb .srt-nfl-ten-line {
  background-color: #f5a623;
  color: $sr-widget-base;
  border-color: #f5a623;
}

.sr-bb .srt-fill-nfl-penalty {
  fill: #f4a621;
}

.sr-bb .srt-stroke-nfl-penalty {
  stroke: #f4a621;
}

.sr-bb .srt-fill-nfl-negative-yards-1 {
  fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-1 {
  stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-negative-yards-2 {
  fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-2 {
  stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-first-line {
  fill: #4b90de;
}

.sr-bb .srt-stroke-nfl-first-line {
  stroke: #4b90de;
}

.sr-bb .srt-fill-nfl-ten-line {
  fill: #f5a623;
}

.sr-bb .srt-stroke-nfl-ten-line {
  stroke: #f5a623;
}

.sr-bb .srt-mlb-run-1 {
  background-color: #090;
  color: $sr-widget-base;
  border-color: #090;
}

.sr-bb .srt-mlb-run-2 {
  background-color: $sr-widget-base;
  color: #090;
  border-color: #090;
}

.sr-bb .srt-mlb-hit-1 {
  background-color: #f7ab11;
  color: $sr-widget-text;
  border-color: #f7ab11;
}

.sr-bb .srt-mlb-hit-2 {
  background-color: $sr-widget-base;
  color: #f7ab11;
  border-color: #f7ab11;
}

.sr-bb .srt-mlb-hit-3 {
  background-color: #00a4ff;
  color: $sr-widget-base;
  border-color: #00a4ff;
}

.sr-bb .srt-mlb-error-1 {
  background-color: #d0021b;
  color: $sr-widget-base;
  border-color: #d0021b;
}

.sr-bb .srt-mlb-error-2 {
  background-color: $sr-widget-base;
  color: #d0021b;
  border-color: #d0021b;
}

.sr-bb .srt-mlb-neutral-1 {
  background-color: #212121;
  color: $sr-widget-base;
  border-color: $sr-widget-base;
}

.sr-bb .srt-fill-mlb-base {
  fill: #00a4ff;
}

.sr-bb .srt-strole-mlb-base {
  fill: #00a4ff;
}

.sr-bb .srt-fill-change-increase {
  fill: $sr-widget-home;
}

.sr-bb .srt-stroke-change-increase {
  stroke: $sr-widget-home;
}

.sr-bb .srt-fill-change-decrease {
  fill: $sr-widget-away;
}

.sr-bb .srt-stroke-change-decrease {
  stroke: $sr-widget-away;
}

.sr-bb .srt-fill-text {
  fill: $sr-widget-text;
}

.sr-bb .srt-fill-text-invert {
  fill: $sr-widget-base;
}

.sr-bb .srt-fill-text-secondary {
  fill-opacity: 0.7;
}

.sr-bb .srt-fill-text-disabled {
  fill-opacity: 0.35;
}

.sr-bb .srt-fill-base-1 {
  fill: transparent;
}

.sr-bb .srt-stroke-base-1 {
  stroke: transparent;
}

.sr-bb .srt-fill-base-1-active {
  fill: rgba(37, 94, 189, 0.12);
}

.sr-bb .srt-stroke-base-1-active {
  stroke: rgba(37, 94, 189, 0.12);
}

.sr-bb .srt-fill-base-1-active-2 {
  fill: #e6e6e6;
}

.sr-bb .srt-stroke-base-1-active-2 {
  stroke: #e6e6e6;
}

.sr-bb .srt-fill-base-1-primary {
  fill: transparent;
}

.sr-bb .srt-stroke-base-1-primary {
  stroke: transparent;
}

.sr-bb .srt-fill-base-1-home {
  fill: transparent;
}

.sr-bb .srt-stroke-base-1-home {
  stroke: transparent;
}

.sr-bb .srt-fill-base-1-away {
  fill: transparent;
}

.sr-bb .srt-stroke-base-1-away {
  stroke: transparent;
}

.sr-bb .srt-fill-base-2 {
  fill: currentColor;
}

.sr-bb .srt-stroke-base-2 {
  stroke: currentColor;
}

.sr-bb .srt-fill-base-2-active {
  fill: #e5ecf7;
}

.sr-bb .srt-stroke-base-2-active {
  stroke: #e5ecf7;
}

.sr-bb .srt-fill-base-2-hover {
  fill: #e5ecf7;
}

.sr-bb .srt-stroke-base-2-hover {
  stroke: #e5ecf7;
}

.sr-bb .srt-fill-base-3 {
  fill: #e5ecf7;
}

.sr-bb .srt-stroke-base-3 {
  stroke: #e5ecf7;
}

.sr-bb .srt-fill-base-3-active {
  fill: #dae4f4;
}

.sr-bb .srt-stroke-base-3-active {
  stroke: #dae4f4;
}

.sr-bb .srt-fill-base-3-hover {
  fill: #dae4f4;
}

.sr-bb .srt-stroke-base-3-hover {
  stroke: #dae4f4;
}

.sr-bb .srt-fill-primary-1 {
  fill: $sr-widget-primary;
}

.sr-bb .srt-stroke-primary-1 {
  stroke: $sr-widget-primary;
}

.sr-bb .srt-fill-primary-2 {
  fill: #1a4284;
}

.sr-bb .srt-stroke-primary-2 {
  stroke: #1a4284;
}

.sr-bb .srt-fill-primary-3 {
  fill: #3b6ec4;
}

.sr-bb .srt-stroke-primary-3 {
  stroke: #3b6ec4;
}

.sr-bb .srt-fill-primary-4 {
  fill: #7c9ed7;
}

.sr-bb .srt-stroke-primary-4 {
  stroke: #7c9ed7;
}

.sr-bb .srt-fill-primary-5 {
  fill: #7c9ed7;
}

.sr-bb .srt-stroke-primary-5 {
  stroke: #7c9ed7;
}

.sr-bb .srt-fill-primary-6 {
  fill: #15376e;
}

.sr-bb .srt-stroke-primary-6 {
  stroke: #15376e;
}

.sr-bb .srt-fill-primary-7 {
  fill: $sr-widget-primary;
}

.sr-bb .srt-stroke-primary-7 {
  stroke: $sr-widget-primary;
}

.sr-bb .srt-fill-primary-8 {
  fill: $sr-widget-primary;
}

.sr-bb .srt-stroke-primary-8 {
  stroke: $sr-widget-primary;
}

.sr-bb .srt-fill-primary-8-is-active-1 {
  fill: #3b6ec4;
}

.sr-bb .srt-stroke-primary-8-is-active-1 {
  stroke: #3b6ec4;
}

.sr-bb .srt-fill-primary-8-is-active-2 {
  fill: #3b6ec4;
}

.sr-bb .srt-stroke-primary-8-is-active-2 {
  stroke: #3b6ec4;
}

.sr-bb .srt-fill-primary-9 {
  fill: #becfeb;
}

.sr-bb .srt-stroke-primary-9 {
  stroke: #becfeb;
}

.sr-bb .srt-fill-primary-10 {
  fill: #517eca;
}

.sr-bb .srt-stroke-primary-10 {
  stroke: #517eca;
}

.sr-bb .srt-fill-primary-11 {
  fill: #3b6ec4;
}

.sr-bb .srt-stroke-primary-11 {
  stroke: #3b6ec4;
}

.sr-bb .srt-fill-primary-12 {
  fill: $sr-widget-primary;
}

.sr-bb .srt-stroke-primary-12 {
  stroke: $sr-widget-primary;
}

.sr-bb .srt-fill-home-1 {
  fill: $sr-widget-home;
}

.sr-bb .srt-stroke-home-1 {
  stroke: $sr-widget-home;
}

.sr-bb .srt-fill-home-2 {
  fill: $sr-widget-home;
}

.sr-bb .srt-stroke-home-2 {
  stroke: $sr-widget-home;
}

.sr-bb .srt-fill-home-3 {
  fill: $sr-widget-home;
}

.sr-bb .srt-stroke-home-3 {
  stroke: $sr-widget-home;
}

.sr-bb .srt-fill-home-4 {
  fill: #5662a1;
}

.sr-bb .srt-stroke-home-4 {
  stroke: #5662a1;
}

.sr-bb .srt-fill-home-5 {
  fill: #09144e;
}

.sr-bb .srt-stroke-home-5 {
  stroke: #09144e;
}

.sr-bb .srt-fill-away-1 {
  fill: $sr-widget-away;
}

.sr-bb .srt-stroke-away-1 {
  stroke: $sr-widget-away;
}

.sr-bb .srt-fill-away-2 {
  fill: $sr-widget-away;
}

.sr-bb .srt-stroke-away-2 {
  stroke: $sr-widget-away;
}

.sr-bb .srt-fill-away-3 {
  fill: $sr-widget-away;
}

.sr-bb .srt-stroke-away-3 {
  stroke: $sr-widget-away;
}

.sr-bb .srt-fill-away-4 {
  fill: #ccaa64;
}

.sr-bb .srt-stroke-away-4 {
  stroke: #ccaa64;
}

.sr-bb .srt-fill-away-5 {
  fill: #765615;
}

.sr-bb .srt-stroke-away-5 {
  stroke: #765615;
}

.sr-bb .srt-fill-neutral-1 {
  fill: #dedede;
}

.sr-bb .srt-stroke-neutral-1 {
  stroke: #dedede;
}

.sr-bb .srt-fill-neutral-2 {
  fill: #1a1a1a;
}

.sr-bb .srt-stroke-neutral-2 {
  stroke: #1a1a1a;
}

.sr-bb .srt-fill-neutral-3 {
  fill: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-stroke-neutral-3 {
  stroke: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-fill-neutral-4 {
  fill: #595959;
}

.sr-bb .srt-stroke-neutral-4 {
  stroke: #595959;
}

.sr-bb .srt-fill-neutral-5 {
  fill: #404040;
}

.sr-bb .srt-stroke-neutral-5 {
  stroke: #404040;
}

.sr-bb .srt-fill-neutral-6 {
  fill: #c2c2c2;
}

.sr-bb .srt-stroke-neutral-6 {
  stroke: #c2c2c2;
}

.sr-bb .srt-fill-neutral-7 {
  fill: #595959;
}

.sr-bb .srt-stroke-neutral-7 {
  stroke: #595959;
}

.sr-bb .srt-fill-neutral-8 {
  fill: #dedede;
}

.sr-bb .srt-stroke-neutral-8 {
  stroke: #dedede;
}

.sr-bb .srt-fill-neutral-9 {
  fill: #f0f0f0;
}

.sr-bb .srt-stroke-neutral-9 {
  stroke: #f0f0f0;
}

.sr-bb .srt-fill-neutral-10 {
  fill: #e0e0e0;
}

.sr-bb .srt-stroke-neutral-10 {
  stroke: #e0e0e0;
}

.sr-bb .srt-fill-neutral-11 {
  fill: #999;
}

.sr-bb .srt-stroke-neutral-11 {
  stroke: #999;
}

.sr-bb .srt-fill-neutral-12 {
  fill: #4d4d4d;
}

.sr-bb .srt-stroke-neutral-12 {
  stroke: #4d4d4d;
}

.sr-bb .srt-fill-neutral-13 {
  fill: #e6e6e6;
}

.sr-bb .srt-stroke-neutral-13 {
  stroke: #e6e6e6;
}

.sr-bb .srt-fill-win {
  fill: $sr-widget-home;
}

.sr-bb .srt-stroke-win {
  stroke: $sr-widget-home;
}

.sr-bb .srt-fill-draw {
  fill: rgba(0, 0, 0, 0.4);
}

.sr-bb .srt-stroke-draw {
  stroke: rgba(0, 0, 0, 0.4);
}

.sr-bb .srt-fill-lose {
  fill: $sr-widget-away;
}

.sr-bb .srt-stroke-lose {
  stroke: $sr-widget-away;
}

.sr-bb .srt-stop-base-1 {
  stop-color: transparent;
}

.sr-bb .srt-stop-primary-1 {
  stop-color: $sr-widget-primary;
}

.sr-bb .srt-stop-primary-2 {
  stop-color: #1a4284;
}

.sr-bb .srt-stop-primary-3 {
  stop-color: #3b6ec4;
}

.sr-bb .srt-stop-primary-4 {
  stop-color: #7c9ed7;
}

.sr-bb .srt-stop-primary-5 {
  stop-color: #7c9ed7;
}

.sr-bb .srt-stop-primary-6 {
  stop-color: #15376e;
}

.sr-bb .srt-stop-primary-7 {
  stop-color: $sr-widget-primary;
}

.sr-bb .srt-stop-primary-8 {
  stop-color: $sr-widget-primary;
}

.sr-bb .srt-stop-primary-9 {
  stop-color: #becfeb;
}

.sr-bb .srt-stop-primary-10 {
  stop-color: #517eca;
}

.sr-bb .srt-stop-primary-11 {
  stop-color: #3b6ec4;
}

.sr-bb .srt-stop-primary-12 {
  stop-color: $sr-widget-primary;
}

.sr-bb .srt-stop-home-1 {
  stop-color: $sr-widget-home;
}

.sr-bb .srt-stop-away-1 {
  stop-color: $sr-widget-away;
}

.sr-bb .srt-fill-neutral-14 {
  fill: $sr-widget-base;
}

.sr-bb .srt-stroke-neutral-14 {
  stroke: $sr-widget-base;
}

.sr-bb .srt-logo-powered-by-light {
  display: inline-block;
}

.sr-bb .srt-logo-powered-by-dark {
  display: none;
}

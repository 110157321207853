@import 'variables';
@import '~src/assets/scss/mixins';

.collapsible {
  transition: height 0.3s ease-in;
  overflow: hidden;
}

.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
  }

  &:active::after {
    transform: scale(0, 0);
    opacity: 0.2;
    transition: 0s;
  }
}

.bold {
  font-weight: bold;
}

.highlighted {
  color: $color-notifications;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.sport-icon,
.flag-icon,
.node-icon,
.virtual-league-icon {
  background-repeat: no-repeat;
  display: block;
  background-size: 100% 100%;
}

.sport-icon {
  @each $name, $value in $sport-icons {
    &.#{$name} {
      background-image: url('/assets/images/sport-icons/#{$value}.svg');

      &.text-dark,
      &.text-light,
      &.brand,
      &.accent,
      &.brand-light {
        background-image: none;
        height: 100%;
        width: 100%;
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
      }

      &.text-dark {
        background-color: $color-text-dark;
        mask-image: url('/assets/images/sport-icons/#{$value}.svg');
      }

      &.text-light {
        background-color: $color-text-light;
        mask-image: url('/assets/images/sport-icons/#{$value}.svg');
      }

      &.brand {
        background-color: rgba($color-text-dark, 0.87);
        mask-image: url('/assets/images/sport-icons/#{$value}.svg');
      }

      &.accent {
        background-color: $color-brand-accent;
        mask-image: url('/assets/images/sport-icons/#{$value}.svg');
      }

      &.brand-light {
        background-color: $color-brand-dark-with-opacity;
        mask-image: url('/assets/images/sport-icons/#{$value}.svg');
      }
    }
  }
}

.flag-icon {
  &.icon-generic {
    background-image: url('/assets/images/flag-icons/Generic.svg');
  }

  @each $name, $value in $flag-icons {
    &.#{$name} {
      background-image: url('/assets/images/flag-icons/#{$value}.svg');
    }
  }
}

.country-icon {
  &.icon-generic {
    background-image: url('/assets/images/flag-icons/Generic.svg');
  }

  @each $name, $value in $country-icons {
    &.#{$name} {
      background-image: url('/assets/images/country-icons/#{$value}.svg');
    }
  }
}

.prefix-flag-icon {
  @each $name, $value in $prefix-flag-icons {
    &.#{$name} {
      background-image: url('/assets/images/prefix-flag-icons/#{$value}.svg');
    }
  }

  border-radius: 50%;
  overflow: hidden;
  background-position: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.node-icon {
  &.icon-generic {
    background-image: url('/assets/images/node-icons/Generic.svg');
  }

  @each $name, $value in $node-icons {
    &.#{$name} {
      background-image: url('/assets/images/node-icons/#{$value}.svg');
    }
  }
}

.virtual-league-icon {
  &.icon-generic {
    background-image: url('/assets/images/virtuals-icons/league-icons/generic.svg');
  }

  @each $name, $value in $virtuals-league-icons {
    &.#{$name} {
      background-image: url('/assets/images/virtuals-icons/league-icons/#{$value}.svg');
    }
  }
}

.hidden {
  display: none !important;
}

.blink {
  animation: blink 1s steps(1, end) infinite alternate;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.cdk-overlay-connected-position-bounding-box {
  left: unset !important;
  width: unset !important;
  -webkit-overflow-scrolling: touch; /* For smoother scrolling on iOS */
}

.disable-container {
  pointer-events: none;
}

.pinned-to-top {
  position: fixed !important;
  top: var(--sibling-height) !important;
}

h1.download-view-title p {
  margin: 0;

  span {
    color: #1affff;
  }
}

.download-view-instructions p span {
  display: block;
  padding-left: 15px;
  padding-right: 20px;
}

@media only screen and (max-width: calc($tablet-size - 1px)) {
  .noscroll-mobile {
    overflow: hidden;
  }
}

// Default shadow to appear globally underneath app-tab
app-tab {
  display: block; // Override at component lvl if you need grid or flex
  box-shadow: 0 3px 4px rgba(black, 0.06);
  position: relative;
}

.sticky {
  position: fixed;
  top: 56px;
  width: 100%;
  opacity: 1;
  background-color: $color-container-background;

  &.sticky-info-line {
    top: 108px;

    &.no-market-tab {
      top: 56px;
    }
  }
}

.sticky-container {
  margin-top: 81.8px;
}

.sticky-0 {
  position: fixed;
  top: 0;
  width: 100%;
  opacity: 1;
  z-index: 5;
  margin-top: 0 !important;
  background-color: $color-container-background;

  &.sticky-info-line {
    top: 52px;
  }
}

@include respond-to('md') {
  .sticky-0 {
    top: 56px;
    padding-top: 10px;
  }
}

@import 'variables';

@mixin no-grid {
  @supports not (display: grid) {
    @content;
  }
}

$breakpoints: (
  'xxs': (
    min-width: 320px,
  ),
  'xs': (
    min-width: 360px,
  ),
  'sm': (
    min-width: 600px,
  ),
  'md': (
    min-width: 900px,
  ),
  'lg': (
    min-width: 1200px,
  ),
  'xl': (
    min-width: 1536px,
  ),
) !default;

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  } @else {
    @warn '`#{$breakpoint}` unavailable. Available breakpoints are: #{map-keys($breakpoints)}.';
  }
}

@mixin sport-homepage-layout {
  :host {
    .sport-section-title {
      color: $color-text-dark-secondary;
      font-weight: normal;
      white-space: nowrap;
      font-size: 16px;
      padding: 20px 16px;
    }

    .gradient-divider {
      background: $gradient-divider;
      margin: 16px 20px 10px;
      height: 1px;
    }

    .divider {
      margin-left: auto;
      width: calc(100% - 16px);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}

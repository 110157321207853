.overlay {
  background-color: rgba(0, 0, 0, 0.75);
}

.bet-loading {
  max-width: 335px;
  width: 90%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  height: 144px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  app-loading-bar {
    width: 100%;
    margin-top: -19px;
  }

  .loading-text {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
}

// Coupon status colors based on: enum CouponStatusID

// Unknown
.coupon-status-0 {
  background-color: $color-container-background; // TODO
}

// Running
.coupon-status-1 {
  background-color: $legend-in-progress;
}

// Lost
.coupon-status-2 {
  background-color: $legend-lost;

  &.lighten-background {
    background-color: lighten($legend-lost, 36%);
  }

  &.text-color {
    color: $legend-lost;
  }
}

// Won
.coupon-status-3 {
  background-color: $legend-won;

  &.lighten-background {
    background-color: lighten($legend-won, 54%);
  }

  &.text-color {
    color: $legend-won;
  }
}

// Cancelled
.coupon-status-4 {
  background-color: $legend-cancelled;
}

// Status colors based on: enum BetFinalState

// Placed
.bet-final-state--1 {
  background-color: $legend-in-progress;

  &.for-lost-and-won {
    background: inherit;
    color: inherit;
  }
}

// InEvaluations
.bet-final-state-0 {
  background-color: $legend-in-evaluation;

  &.for-lost-and-won {
    background: inherit;
    color: inherit;
  }
}

// Won
.bet-final-state-1 {
  background-color: $legend-won;

  &.lighten-background {
    background-color: lighten($legend-won, 54%);
  }

  &.text-color {
    color: $legend-won;
  }
}

.bet-final-state-2 {
  // background: linear-gradient(
  //   to bottom,
  //   $color-success 0%,
  //   $color-success 50%,
  //   $color-brand-accent 51%,
  //   $color-brand-accent 100%
  // );

  background-color: $legend-partially-won;

  &.lighten-background {
    background-color: lighten($legend-won, 54%);
  }

  &.text-color {
    color: $legend-won;
  }
}

// Lost
.bet-final-state-3 {
  background-color: $legend-lost;

  &.lighten-background {
    background-color: lighten($legend-lost, 34%);
  }

  &.text-color {
    color: $legend-lost;
  }
}

// Void
.bet-final-state-4 {
  &.voided {
    background-color: $legend-void;
  }

  &.cancelled {
    background-color: $legend-cancelled;
  }

  &.for-lost-and-won {
    background: inherit;
    color: inherit;
  }
}

// Cashout
.bet-final-state-5 {
  background-color: $legend-cashout;

  &.for-lost-and-won {
    background: inherit;
    color: inherit;
  }
}

// Promotion
.bet-final-state-6 {
  background-color: $legend-promotion;

  &.for-lost-and-won {
    background: inherit;
    color: inherit;
  }
}

.odd-result-status--2 {
  background-color: $legend-in-progress;
}

.odd-result-status--1 {
  background-color: $legend-void;
}

.odd-result-status-0 {
  background-color: $legend-lost;
}

.odd-result-status-1 {
  background-color: $legend-won;
}

.odd-result-status-4 {
  background-color: $legend-partially-won;
}

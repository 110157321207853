@import '~src/assets/scss/theme/variables';

$color-brand: #255dbd;
$color-brand-light: #4a6097;
$color-brand-dark: #0e1e78;
$color-brand-darker: #001041;
$color-brand-accent: #ffc400;
$color-brand-accent-secondary: #1affff;
$color-brand-highlight: #b68521;
$color-brand-live-highlight: #03a9f4;
$color-odd: #e3f1fd;
$color-odd-text: #2562bd;
$color-odd-selected: $color-brand-darker;
$color-odd-text-selected: #3ff;
$color-boosted-odd-text-selected: #ffc400;
$color-light-text: #000c2d60;

// Loading
$loading-background-color: #fff;
$loading-bar-color: $color-brand-darker;

// Logo
$login-logo-width: 10vh;
$login-logo-height: 75%;

// Sportsradar widget
$sr-widget-primary: rgba(0, 0, 0, 0.38);
$sr-widget-home: #0e1e78;
$sr-widget-away: #b68521;

$selection-bg-color: #fafafa;
$sport-chooser-selected-bg: #1f2f58;

$gradient-divider: linear-gradient(270deg, rgba(#001041, 0) 0%, rgba(#001041, 0.12) 50%, rgba(#001041, 0) 100%);

// Info Modal
$info-modal-header-background: linear-gradient(82.02deg, #c2780a 0%, #ffc400 71.86%);
$info-modal-overlay: rgba(#001041, 0.64);

// Virtuals
$virtuals-league-gradients: (
  // kings-league
  gradient-2660312: linear-gradient(129.53deg, #2662d9 8.39%, #121d73 92.63%),
  // kings-liga
  gradient-2660314: linear-gradient(130.23deg, #ffc400 8.46%, #ffc400 8.46%, #aa6f09 93.03%),
  // kings-italiano
  gradient-3250280: linear-gradient(129.9deg, #269dd9 7.69%, #084191 92.01%),
  // kings-bundliga
  gradient-16337569: linear-gradient(115.95deg, #e51c3e 16.21%, #930241 83.48%)
);

// Virtuals league icons
$virtuals-league-icons: (
  icon-2660312: kings-league,
  icon-2660314: kings-liga,
  icon-3250280: kings-italiano,
  icon-16337569: kings-bundliga,
  icon-78777649: kings-bundliga,
  icon-72026820: kings-bundliga,
);

// X-Sell
$x-sell-default-bg-color: $color-container-background;
$x-sell-default-icon-color: $color-brand;

// Accumulator Bonus
$acc-bonus-container-text-color: $color-brand-darker;
$acc-bonus-container-amount-color: $color-brand-darker;

// Football go result detail
$color-away-results: #dd122a;
$color-home-results: #1263dd;

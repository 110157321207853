@import '~src/assets/scss/functions';

// NATIVE DATE INPUT OVERWRITE
input[type='date'] {
  position: relative;
  width: 100%;
  height: 38px;
  font-size: 16px;
  color: $color-text-dark;
  padding: 10px;
  appearance: none;
  border: 1px solid darken($color-border, 20%);
  background-color: $color-container-background;
  text-transform: uppercase;

  // create a new arrow
  &:not(:disabled) {
    &::after {
      content: '\f073';
      color: $color-brand;
      font-family: FontAwesome;
      font-style: normal;
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }

  &:hover {
    outline-color: $color-brand;
  }

  &.brand {
    color: $color-text-light;
    background-color: $color-brand;
    font-weight: bold;
    height: 43px;
    border-radius: $brand-dropdown-border-radius-size;
    border: 1px solid $color-brand;

    &::after {
      color: $color-text-light;
      font-weight: normal;
    }

    &:hover {
      outline-color: transparent;
    }
  }
}

input[type='date']::-webkit-calendar-picker-indicator {
  content: '\f073';
  position: relative;
  left: 10px;
  z-index: 1000;
  color: transparent;
  background: transparent;
}

// Adjust increase/decrease button - webkit
input[type='date']::-webkit-inner-spin-button {
  z-index: 1;
}

// Adjust clear button - webkit
input[type='date']::-webkit-clear-button {
  display: none;
}

// Adjust clear button - ms
input[type='date']::-ms-clear {
  display: none;
}

input[type='date']:disabled {
  background-color: rgb(235, 235, 228);
  color: graytext;
}

// NATIVE SELECT-OPTION INPUT OVERWRITE
.dropdown {
  position: relative;
  width: 100%;
  font-size: 16px;

  &:not(.no-arrow) {
    &::after {
      content: '\f078';
      font-family: FontAwesome;
      color: $color-brand;
      position: absolute;
      pointer-events: none;
      right: 10px;
      top: 10px;
    }
  }

  &.brand {
    &::after {
      top: 13px;
      color: set-text-color($color-brand);
    }

    &.disabled {
      &::after {
        display: none;
      }

      select {
        opacity: 0.7;
      }
    }

    select {
      font-weight: bold;
      height: 43px;
      color: set-text-color($color-brand);
      background-color: $color-brand;
      border-radius: $brand-dropdown-border-radius-size;
      outline-color: transparent;
      border: 1px solid $color-brand;

      &.ng-pristine {
        color: set-text-color($color-brand);
        font-style: normal;

        option:not(:disabled) {
          color: $color-text-light;
        }
      }
    }
  }

  select {
    font-weight: normal;
    border: 1px solid darken($color-border, 20%);
    outline-color: $color-brand;
    font-size: 16px;
    color: set-text-color($color-container-background);
    background-color: $color-container-background;
    appearance: none;
    padding-right: 30px;

    &::-ms-expand {
      display: none;
    }

    &.ng-pristine {
      color: graytext;

      option:not(:disabled) {
        color: $color-text-dark;
      }
    }

    &:disabled {
      background-color: rgb(235, 235, 228);
    }
  }

  option {
    font-style: normal;
  }
}

// NATIVE INPUT OVERRIDE
input[type='text'],
input[type='number'],
input[type='tel'],
[type='password'] {
  border: 1px solid darken($color-border, 20%);
  outline-color: $color-brand;
  font-size: 16px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// NATIVE CHECKBOX OVERRIDE
input[type='checkbox'],
input[type='radio'] {
  display: none;
}

input[type='checkbox']+.checkbox-visual {
  cursor: pointer;
  width: 23px;
  height: 23px;
  min-width: 23px;
  min-height: 23px;
  background: $color-container-background;
  border: 3px solid $color-brand;
  border-radius: 2px;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

input[type='checkbox']:checked+.checkbox-visual {
  color: $color-container-background;
  background: $color-brand;
  border: 3px solid $color-brand;
}

input[type='checkbox']:checked+.checkbox-visual::after {
  content: '\f00c';
  font-family: FontAwesome;
  font-size: 18px;
  color: white;
}

input[type='checkbox'].small+.checkbox-visual {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
}

input[type='checkbox']:checked.small+.checkbox-visual::after {
  content: '\f00c';
  font-size: 14px;
}

// NATIVE RADIO BUTTON OVERRIDE
input[type='radio']+.radio-visual {
  cursor: pointer;
  width: 23px;
  height: 23px;
  min-width: 23px;
  min-height: 23px;
  background: $color-container-background;
  border: 2px solid $color-brand;
  border-radius: 50%;
  margin-right: 10px;
  margin-bottom: 2px;
}

input[type='radio']:checked+.radio-visual::after {
  content: '';
  display: block;
  border-radius: 50%;
  font-weight: bold;
  border: 2px $color-brand solid;
  background-color: $color-brand;
  width: 13px;
  height: 13px;
  position: relative;
  top: 3px;
  left: 3px;
}

.radio-item-group {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

// GENERAL OVERRIDES
select,
input {
  width: 100%;
  padding: 10px;
}

input::placeholder,
select.ng-pristine,
option:disabled {
  font-style: italic;
}

// NATIVE TEXT AREA OVERRIDE
textarea {
  width: 100%;
  font-size: 16px;
  height: 8em;
  padding: 0.6em 0.5em;
  border: 2px solid $color-border-alt;
}
